.youtube-carousel {
  width: 100%;
  padding: 20px 0;
  position: relative;
  display: flex;
  justify-content: center;
}

.carousel {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 20px;
  justify-items: center;
  padding: 0 5px;
}

.video {
  width: 100%;
  height: 150px;
  overflow: hidden;
}

.video iframe {
  top: 0;
  left: 0;
  width: 250px;
  height: 150px;
}

button {
  margin-top: 20px;
}

.scroll-button {
  position: absolute;
  top: 45%;
  transform: translateY(-50%);
  background: none;
  border: none;
  color: gray;
  font-size: 40px;
  cursor: pointer;
}

.left {
  left: 10px;
}

.right {
  right: 10px;
}

@media only screen and (max-width: 1420px) {
  .carousel {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 20px;
    justify-items: center;
    padding: 0 5px;
}
}
@media only screen and (max-width: 1120px) {
  .carousel {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 20px;
    justify-items: center;
    padding: 0 5px;
}
}

@media only screen and (max-width: 820px) {
  .carousel {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 20px;
    justify-items: center;
    padding: 0 5px;
}
}

@media only screen and (max-width: 820px) {
  .carousel {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 20px;
    justify-items: center;
    padding: 0 5px;
}
}