.tabs-container {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  margin-bottom: 20px;
  background-image: url("../images/backgroundImage.jpeg");
  align-items: center;
}

.tabs {
  display: flex;
  flex-direction: column;
  width: 50%;
  height: 100%;
  padding: 30px 20px;
  margin-bottom: 10px;
}

.tab {
  padding: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  text-align: center;
  color: white;
  font-size: 30px;
  
}

.content {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 50%;
  padding: 30px 40px;
  margin-bottom: 10px;
  
}

.big-image {
  width: 80%;
  border-radius: 30px;
  border: 3px solid #510205;
  box-shadow: 0 0 10px rgba(255, 255, 255, 1); /* Added white border shadow */
}

.tab {
  padding: 10px;
  background: linear-gradient(to bottom, #FDD433, #FEB33A);
  color: #510205;
  font-size: 40px;
  font-weight: 700; /* Changed font-weight to bold */
  border-radius: 10px;
  font-family: "shreyas";
  position: relative;
  margin-bottom: 10px;
  margin-left: 150px;
  transition: transform 0.3s ease;
  text-decoration: none;
}


.tab:hover {
  transform: scale(1.1); 
}
@media only screen and (max-width: 530px) {
 .tab{
  font-size: 20px;
 }
}
@media only screen and (max-width: 1700px) {
  .tab {
    padding: 10px;
    background: linear-gradient(to bottom, #FDD433, #FEB33A);
    color: #510205;
    font-size: 30px;
    font-weight: 700; /* Changed font-weight to bold */
    border-radius: 10px;
    font-family: "shreyas";
    position: relative;
    margin-bottom: 10px;
    margin-left: 150px;
    transition: transform 0.3s ease;
    text-decoration: none;
  }
}

@media only screen and (max-width: 1400px) {
  .tab {
    padding: 10px;
    background: linear-gradient(to bottom, #FDD433, #FEB33A);
    color: #510205;
    font-size: 20px;
    font-weight: 700; /* Changed font-weight to bold */
    border-radius: 10px;
    font-family: "shreyas";
    position: relative;
    margin-bottom: 10px;
    margin-left: 150px;
    transition: transform 0.3s ease;
    text-decoration: none;
  }
}

@media only screen and (max-width: 1100px) {
  .tab {
    padding: 10px;
    background: linear-gradient(to bottom, #FDD433, #FEB33A);
    color: #510205;
    font-size: 10px;
    font-weight: 700; /* Changed font-weight to bold */
    border-radius: 10px;
    font-family: "shreyas";
    position: relative;
    margin-bottom: 10px;
    margin-left: 150px;
    transition: transform 0.3s ease;
    text-decoration: none;
  }
}

@media only screen and (max-width: 900px) {
  .tab {
    padding: 10px;
    background: linear-gradient(to bottom, #FDD433, #FEB33A);
    color: #510205;
    font-size: 10px;
    font-weight: 700; /* Changed font-weight to bold */
    border-radius: 10px;
    font-family: "shreyas";
    position: relative;
    margin-bottom: 10px;
    margin-left: 100px;
    transition: transform 0.3s ease;
    text-decoration: none;
  }
}

@media only screen and (max-width: 700px) {
  .tab {
    padding: 10px;
    background: linear-gradient(to bottom, #FDD433, #FEB33A);
    color: #510205;
    font-size: 10px;
    font-weight: 700; /* Changed font-weight to bold */
    border-radius: 10px;
    font-family: "shreyas";
    position: relative;
    margin-bottom: 10px;
    margin-left: 50px;
    transition: transform 0.3s ease;
    text-decoration: none;
  }
}


@media only screen and (max-width: 550px) {
  .tab {
    padding: 10px;
    background: linear-gradient(to bottom, #FDD433, #FEB33A);
    color: #510205;
    font-size: 10px;
    font-weight: 700; /* Changed font-weight to bold */
    border-radius: 10px;
    font-family: "shreyas";
    position: relative;
    margin-bottom: 10px;
    margin-left: 0px;
    transition: transform 0.3s ease;
    text-decoration: none;
  }

  .counter2 {
    color: #fdd433;
    font-family: "shreyas";
    font-size: 30px;
    padding: 35px 0;
    font-weight: 700;
    line-height: 50px;
    margin: 0;
    text-align: center;
}
  .tabs-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: 100%;
    margin-bottom: 20px;
    background-image: url("../images/backgroundImage.jpeg");
    align-items: center;
  }
  .content {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
    padding: 10px 0px;
    margin-bottom: 10px;
}
}