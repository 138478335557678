.main-divs {
  background-color: rgb(247, 247, 247);
}
@font-face {
  font-family: "DigitalWatchFont";
  font-family: "DigitalWatchFont";
  src: url("../assets/fonts/DS-DIGIT.TTF") format("truetype");
}

@font-face {
  font-family: "shreyas";
  font-family: "shreyas";
  src: url("../assets/fonts/Pragathi.ttf") format("truetype");
}

.digital-font {
  font-family: "DigitalWatchFont", monospace;
  font-size: 2em;
  color: #ff0000;
}
.countdown-timer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  font-size: 5rem;
  font-weight: bold;
  font-family: "DigitalWatchFont", monospace;
  color: #fff;
  padding: 1rem;
  background-image: url("../images/backgroundImage.jpeg");
}
.countdown-timer2 {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  font-size: 5rem;
  font-weight: bold;
  font-family: "DigitalWatchFont", monospace;
  color: #fff;
  padding: 1rem;
}

.timer-value {
  margin: 0 1rem;
}
.text-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: Arial, sans-serif;
  background-color: #f2f2f2;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.telugu-text {
  font-size: 24px;
  font-weight: bold;
  line-height: 1.5;
  text-align: center;
  margin: 0;
  color: #333;
}

.timer-label {
  margin: 0 1rem;
  color: #fdd433;
  font-family: "shreyas";
  font-weight: 700;
  font-size: 1.2rem;
}

h1 {
  color: rgb(33, 56, 117);
  font-family: "Noto Sans Telugu", sans-serif;
  font-size: 52px;
  font-weight: 700;
  line-height: 60px;
  -webkit-text-stroke-color: rgb(0, 0, 0);
  stroke: rgb(0, 0, 0);
  text-shadow: rgba(0, 0, 0, 0.3) 0px 0px 5px;
  text-align: center;
}
.main-head {
  padding: 2px 0px;
  border-style: solid;
  border-width: 7px 0px;
  margin: 10px 10px;
}
.main-head2 {
  padding: 2px 0px;
  border-style: solid;
  border-width: 7px 0px;
  margin: 10px 150px;
}
.hero-image {
  width: 100%;
}
.popup {
  position: fixed;
  background-image: url("../images/backgroundImage.jpeg");
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  padding: 20px;
  z-index: 1000;
}

.popup-content {
  text-align: center;
}

.button-container {
  display: flex;
  justify-content: center;
  gap: 10px;
}

.yes-button,
.no-button {
  padding: 10px 20px;
  font-size: 16px;
  border: none;
  border-radius: 30px;
  cursor: pointer;
}

.yes-button {
  background-color: #df0505;
  border: none;
  color: #ffffff;
  font-weight: bold;
  font-size: 1.5em;
  padding: 10px 20px;
  text-transform: uppercase;
  cursor: pointer;
}

.yes-button:hover {
  background-color: #a50909;
}
.yes2-button,
.no-button {
  padding: 10px 20px;
  font-size: 16px;
  border: none;
  border-radius: 30px;
  cursor: pointer;
}

.yes2-button {
  background: linear-gradient(to bottom, #fdd433, #feb33a);
  color: #510205;
  border: none;
  font-weight: bold;
  font-size: 1.5em;
  padding: 10px 20px;
  text-transform: uppercase;
  cursor: pointer;
  margin-top: 5px;
}

.yes2-button:hover {
  background: linear-gradient(to bottom, #fdd433, #f1a11f);
  color: #510205;
}

.no-button {
  background-color: #f44336;
  color: white;
}
.one-text {
  color: rgb(22, 77, 179);
  font-size: 45px;
  margin: 0;
  font-weight: 700;
}
.two-text {
  color: #b61212;
  font-size: 70px;
  margin: 0;
  font-weight: 700;
}
.three-text {
  color: #278f2b;
  font-size: 45px;
  margin: 0;
  font-weight: 700;
}
.four-text {
  color: #ffffff;
  font-size: 30px;
  margin-top: 10px;
  margin-bottom: 10px;
  font-weight: 700;
}
.exit-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: transparent;
  border: none;
  color: #000000;
  font-size: 20px;
  cursor: pointer;
  background-color: #ffffff;
  border-radius: 50px;
}

.exit-button:hover {
  color: #ffffff;
  background-color: #000;
}

.counter2 {
  color: #fdd433;
  font-family: "shreyas";
  font-size: 70px;
  padding: 35px 0;
  font-weight: 700;
  line-height: 101.65px;
  margin: 0;
  text-align: center;
}

.counter3 {
  color: #fdd433;
  font-family: "shreyas";
  font-size: 70px;
  font-weight: 700;

  line-height: 101.65px;
}
.counter4 {
  color: #fdd433;
  font-family: "shreyas";
  font-size: 60px;
  font-weight: 700;
  line-height: 101.65px;
  text-align: center;
  margin: 5px;
}

.counter5 {
  color: #ffffff;
  font-family: "shreyas";
  font-size: 40px;
  font-weight: 700;
  line-height: 101.65px;
  text-align: center;
  margin: 0;
}
.counter6 {
  color: #fdd433;
  font-family: "shreyas";
  font-size: 40px;
  font-weight: 700;
  line-height: 101.65px;
  text-align: center;
  margin: 0;
}
.counter {
  color: #ffffff;
  font-size: 120px;
  margin: 0;
  font-weight: 700;
}
.counter8 {
  color: #ffffff;
  font-family: "shreyas";
  font-size: 40px;
  font-weight: 700;
  text-align: left;
  margin: 0;
}
.counter-main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 10px 0;
  align-items: center;
  background-image: url("../images/backgroundImage2.jpeg");
}
.counter-mains {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 10px 0;
  align-items: center;
}

.counter-main2 {
  display: flex;
  justify-content: center;
  padding: 10px 0;
  align-items: center;
  background-image: url("../images/backgroundImage2.jpeg");
}

.video-div {
  display: flex;
  flex-direction: column;
  justify-items: center;
  padding: 30px 0;
  align-items: center;
  background-image: url("../images/backgroundImage2.jpeg");
}

.counter-main4 {
  display: flex;
  flex-direction: column;
  justify-items: center;
  padding: 30px 0;
  align-items: center;
  background-image: url("../images/backgroundImage.jpeg");
}

.two-divs {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 10px;
  width: 80%;
}

.video-screen {
  display: flex;
  justify-content: center;
  width: 50%;
}
.video-text {
  color: #ffffff;
  font-family: "shreyas";
  font-size: 40px;
  font-weight: 700;
  text-align: left;
  margin: 5px;
  width: 50%;
  display: flex;
  flex-direction: column;
}

.download {
  background: linear-gradient(to bottom, #fdd433, #feb33a);
  color: #510205;
  width: fit-content;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 32px;
  font-weight: 700;
  font-family: "shreyas";
  margin: 10px 2px;
  cursor: pointer;
  border: none;
  border-radius: 20px;
}

.popup-image {
  width: 100%;
}

.counter {
  animation: countAnimation 1s ease-out forwards;
  opacity: 0;
  font-family: "DigitalWatchFont", monospace;
}
.grid-image {
  width: 100%;
  height: 200px;
  transition: transform 0.3s ease;
}

.grid-image:hover {
  transform: scale(1.1);
}

.image-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  justify-items: center;
}

.image-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 24px;
  font-weight: 600;
  color: #ffffff;
}

.image-title {
  color: white;
  text-align: center;
}
p {
  font-size: 20px;
  font-weight: 600;
}
.main-grid-div {
  display: flex;
  flex-direction: column;
  padding: 40px 0;
  background-image: url("../images/backgroundImage.jpeg");
}
.image-grid2 {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  justify-items: center;
}

.video-div {
  background-image: url("../images/backgroundImage2.jpeg");
}

.image-container2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 24px;
  font-weight: 600;
  color: #ffffff;
}

@keyframes countAnimation {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.side-image {
  width: 150px;
  height: 150px;
}

.side-image2{
  width: 350px;
  height: 350px;
  display: none;
}
/* @media only screen and (max-width: 1050px) {
  .banner-image {
    height: 400px;
  }
}

@media only screen and (max-width: 950px) {
  .banner-image {
    height: 400px;
  }
} */

@media only screen and (max-width: 1920px) {
  .banner-image {
    height: 400px;
  }
  .two-text {
    color: #b61212;
    font-size: 40px;
    margin: 0;
    font-weight: 700;
  }
  /* .counter {
    color: #b61212;
    font-size: 40px;
    margin: 0;
    font-weight: 700;
  } */
  .one-text {
    color: rgb(22, 77, 179);
    font-size: 25px;
    margin: 0;
    font-weight: 700;
  }
  .three-text {
    color: #278f2b;
    font-size: 25px;
    margin: 0;
    font-weight: 700;
  }
  .four-text {
    color: #ffffff;
    font-size: 20px;
    margin-top: 10px;
    margin-bottom: 10px;
    font-weight: 700;
  }
}

@media only screen and (max-width: 1250px) {
  .video-screen {
    display: flex;
    justify-content: center;
    width: 50%;
  }
  .video-text {
    color: #ffffff;
    font-family: "shreyas";
    font-size: 40px;
    font-weight: 700;
    text-align: left;
    margin: 5px;
    width: 50%;
    display: flex;
    flex-direction: column;
  }
  .counter {
    color: #ffffff;
    font-size: 100px;
    margin: 0;
    font-weight: 700;
  }
}

@media only screen and (max-width: 1110px) {
  .banner-image {
    height: 400px;
  }
  .two-text {
    color: #b61212;
    font-size: 40px;
    margin: 0;
    font-weight: 700;
  }
  /* .counter {
    color: #b61212;
    font-size: 40px;
    margin: 0;
    font-weight: 700;
  } */
  .one-text {
    color: rgb(22, 77, 179);
    font-size: 25px;
    margin: 0;
    font-weight: 700;
  }
  .three-text {
    color: #278f2b;
    font-size: 25px;
    margin: 0;
    font-weight: 700;
  }
  .four-text {
    color: #ffffff;
    font-size: 20px;
    margin-top: 10px;
    margin-bottom: 10px;
    font-weight: 700;
  }
  .image-grid2 {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    justify-items: center;
  }
}

@media only screen and (max-width: 950px) {
  .image-grid {
    grid-template-columns: repeat(2, 1fr);
  }
  .image-grid2 {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    justify-items: center;
  }

  .yes-button {
    background-color: #df0505;
    border: none;
    color: #ffffff;
    font-weight: bold;
    font-size: 1.2em;
    padding: 10px 20px;
    text-transform: uppercase;
    cursor: pointer;
  }
  .counter4 {
    color: #fdd433;
    font-family: "shreyas";
    font-size: 30px;
    font-weight: 700;
    line-height: 101.65px;
    text-align: center;
    margin: 5px;
  }
  .two-divs {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 10px;
  }
  .video-screen {
    display: flex;
    justify-content: center;
    width: 100%;
  }
  .video-text {
    color: #ffffff;
    font-family: "shreyas";
    font-size: 40px;
    font-weight: 700;
    text-align: left;
    margin: 5px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .counter8 {
    color: #ffffff;
    font-family: "shreyas";
    font-size: 30px;
    font-weight: 700;
    text-align: center;
    margin: 0;
  }
  .counter {
    color: #ffffff;
    font-size: 100px;
    margin: 0;
    font-weight: 700;
  }
}

@media only screen and (max-width: 750px) {
  .image-grid {
    grid-template-columns: repeat(1, 1fr);
  }
  .main-head2 {
    margin: 0 5px;
  }
  .image-grid {
    margin: 0 5px;
  }
  .image-grid2 {
    margin: 0 5px;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    justify-items: center;
  }
  .image-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 20px;
    font-weight: 600;
    color: #000;
  }
  .counter4 {
    color: #fdd433;
    font-family: "shreyas";
    font-size: 15px;
    font-weight: 700;
    line-height: 30px;
    text-align: center;
    margin: 5px;
  }
  .counter5 {
    color: #ffffff;
    font-family: "shreyas";
    font-size: 15px;
    font-weight: 700;
    line-height: 101.65px;
    text-align: center;
    margin: 0;
  }
  .counter8 {
    color: #ffffff;
    font-family: "shreyas";
    font-size: 15px;
    font-weight: 700;
    text-align: center;
    line-height: 40px;
    margin: 0;
}
  .counter6 {
    color: #fdd433;
    font-family: "shreyas";
    font-size: 15px;
    font-weight: 700;
    line-height: 10px;
    text-align: center;
    margin: 0;
  }
  .yes2-button {
    background: linear-gradient(to bottom, #fdd433, #feb33a);
    color: #510205;
    border: none;
    font-weight: bold;
    font-size: 1em;
    padding: 10px 20px;
    text-transform: uppercase;
    cursor: pointer;
    margin-top: 5px;
  }
  .two-text {
    color: #b61212;
    font-size: 30px;
    margin: 0;
    font-weight: 700;
}
}

@media only screen and (max-width: 550px) {
  .countdown-timer {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 3.5rem;
    font-weight: bold;
    color: #fff;
    background-color: #000;
    padding: 1rem;
  }
  .countdown-timer2 {
    display: grid;
    grid-template-columns: repeat(4,1fr);
    align-items: center;
    justify-content: center;
    font-size: 3.5rem;
    font-weight: bold;
    color: #fff;
    padding: 0px;
    width: 90%;
  }
  .popup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    padding: 15px;
    z-index: 1000;
}
  .counter2 {
    color: #fdd433;
    font-family: "shreyas";
    font-size: 50px;
    padding: 35px 0;
    font-weight: 700;
    line-height: 101.65px;
    margin: 0;
    text-align: center;
  }
  .download {
    background: linear-gradient(to bottom, #fdd433, #feb33a);
    color: #510205;
    width: -moz-fit-content;
    width: fit-content;
    padding: 10px 20px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 15px;
    font-weight: 700;
    font-family: "shreyas";
    margin: 10px 2px;
    cursor: pointer;
    border: none;
    border-radius: 20px;
  }
  .side-image {
    width: 150px;
    height: 150px;
    display: none;
  }
  iframe {
    height: 200px;
  }
  .side-image2{
    width: 150px;
    height: 150px;
    display: block;
  }
}
